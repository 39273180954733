@import 'https://code.highcharts.com/11.1.0/css/highcharts.css';
@font-face {
  font-family: 'Inter var';
  font-weight: 400 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('/Inter-roman.var.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter var';
  font-weight: 400 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url('/Inter-italic.var.woff2') format('woff2');
}

/*Proxima Nova*/
@font-face {
    font-family: 'Proxima Nova';
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url('/proximanova-regular.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    src: url('/proximanova-medium.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: url('/proximanova-semibold.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: url('/proximanova-bold.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    src: url('/proximanova-regularit.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-display: swap;
    font-style: italic;
    font-weight: 500;
    src: url('/proximanova-mediumit.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-display: swap;
    font-style: italic;
    font-weight: 600;
    src: url('/proximanova-semiboldit.otf') format('opentype');
}

@font-face {
    font-family: 'Proxima Nova';
    font-display: swap;
    font-style: italic;
    font-weight: 700;
    src: url('/proximanova-boldit.otf') format('opentype');
}

